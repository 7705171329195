import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import { responsiveImage, properCase } from '../../utils/helpers.js'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-regular-svg-icons'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'

export default ({ data, location }) => {
  const news = data.allContentfulNews.edges
  const legal = data.legal.edges
  const hero = responsiveImage(data.heroImage.childImageSharp.fluid.srcSetWebp)

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title="UNICOM Digital - Sitemap"
          description="Use this sitemap to find the content you're looking for and then navigate your way around the UNICOM Digital website."
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + hero}
        />
        <Hero
          class="intro-40"
          image={hero}
          title="UNICOM Digital sitemap"
          subtitle={null}
          type="sitemap"
        />
      </div>
      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <MDBRow>
                <MDBCol md="6" lg="4" className="mb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Home <Link to="/" className="ml-2 sitemap"> <FontAwesomeIcon icon={faLink} /> </Link> </h2>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Company </h2>

                  <p className="font-w-400 text-medium">
                    <Link
                      to="/about/"
                      className="effect-noline"
                    >
                      About UNICOM Global
                    </Link>
                  </p>
                  <p className="font-w-400 text-medium">
                    <Link
                      to="/contact/"
                      className="effect-noline"
                    >
                      Contact us
                    </Link>
                  </p>
                  <p className="font-w-400 text-medium">
                    <Link
                      to="/about/corry-hong-founder-and-ceo-unicom-global/"
                      className="effect-noline"
                    >
                      Corry Hong - Founder and CEO
                    </Link>
                  </p>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> UNICOM Global divisions <Link to="/unicom-global/divisions/" className="ml-2"> <FontAwesomeIcon icon={faLink} /> </Link> </h2>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Search <Link to="/search/" className="ml-2 sitemap"> <FontAwesomeIcon icon={faLink} /> </Link> </h2>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5"> Privacy &amp; legal </h2>
                  {legal.map(({ node }, index) => (
                    <p className="font-w-400 text-medium" key={index}> <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link> </p>
                  ))}                 
                </MDBCol>

                <MDBCol md="6" lg="4" className="mb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Methodology <Link to="/methodology/" className="ml-2 sitemap"> <FontAwesomeIcon icon={faLink} /> </Link> </h2>

                  <p className="font-w-400 text-medium"> <Link to="/methodology/#get-started" className="effect-noline" > Getting started </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/methodology/#lay-foundations" className="effect-noline" > Laying the foundations </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/methodology/#road-ahead" className="effect-noline" > The road ahead </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/methodology/#move-forward" className="effect-noline" > Moving forward </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/methodology/#stay-on-track" className="effect-noline" > Staying on track </Link> </p>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Services <Link to="/services/" className="ml-2 sitemap"> <FontAwesomeIcon icon={faLink} /> </Link> </h2>

                  <p className="font-w-400 text-medium"> <Link to="/services/#project-management" className="effect-noline" > Project management </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/services/#consultancy" className="effect-noline" > Consultancy </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/services/#design" className="effect-noline" > Design </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/services/#implementation" className="effect-noline" > Implementation </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/services/#support" className="effect-noline" > Support </Link> </p>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Solutions <Link to="/solutions/" className="ml-2 sitemap"> <FontAwesomeIcon icon={faLink} /> </Link> </h2>

                  {data.allMarkdownRemark.edges.map(({ node }, index) => (
                    <>
                      <p className="font-w-400 text-medium">
                        <Link
                          to={node.fields.slug}
                          className="effect-noline"
                        >
                          {node.fields.slug
                            .replace('/solutions/', '')
                            .replace('/', '')
                            .replace('-', ' ')[0]
                            .toUpperCase() +
                            node.fields.slug
                              .replace('/solutions/', '')
                              .replace('/', '')
                              .replace('-', ' ')
                              .slice(1)}
                        </Link>
                        {node.fields.slug ===
                          '/solutions/digital-experience/' && (
                          <>
                            <br />
                            <Link to="/solutions/digital-experience/application-accessibility/" className="effect-noline indented" > - Application accessibility </Link>
                            <br />
                            <Link to="/solutions/digital-experience/operational-intelligence/" className="effect-noline indented" > - Operational intelligence </Link>
                            <br />
                            <Link to="/solutions/digital-experience/automated-assistance/" className="effect-noline indented" > - Automated assistance </Link>
                            <br />
                            <Link to="/solutions/digital-experience/regulatory-compliance/" className="effect-noline indented" > - Regulatory compliance </Link>
                          </>
                        )}
                      </p>
                    </>
                  ))}
                  <p className="font-w-400 text-medium"> <Link to="/solutions/unigw-the-unicom-universal-gateway/" className="effect-noline" > UniGW: The UNICOM universal gateway </Link> </p>


                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Corporate financing <Link to="/corporate-financing/" className="ml-2 sitemap"> <FontAwesomeIcon icon={faLink} /> </Link> </h2>

                  <p className="font-w-400 text-medium"> <Link to="/corporate-financing/#macroeconomic-technology-vision" className="effect-noline" > Macroeconomic technology vision </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/corporate-financing/#mergers-and-acquisitions" className="effect-noline" > Mergers and acquisitions </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/corporate-financing/#joint-ventures" className="effect-noline" > Joint ventures </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/corporate-financing/#special-purpose-acquisition-companies" className="effect-noline" > Special-purpose acquisition companies </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/corporate-financing/#initial-public-offerings" className="effect-noline" > Initial public offerings </Link> </p>
                </MDBCol>

                <MDBCol md="6" lg="4" className="mb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Lab <Link to="/lab/" className="ml-2 sitemap"> <FontAwesomeIcon icon={faLink} /> </Link> </h2>

                  <p className="font-w-400 text-medium"> <Link to="/lab/#physical" className="effect-noline"> Physical innovation centers </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/lab/#virtual" className="effect-noline"> Virtual innovation center </Link> </p>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                    Newsroom
                    <Link to="/news/" className="ml-2">
                      <FontAwesomeIcon icon={faLink} />
                    </Link>
                  </h2>
                  {news.map(({ node }, index) => (
                    <p className="font-w-400 text-medium" key={index}>
                      <Link
                        to={`/news/${node.slug}/`}
                        className="effect-noline"
                      >
                        {properCase(node.title)}
                      </Link>
                    </p>
                  ))}
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query SitemapQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "solutions" } } }
      sort: { fields: frontmatter___sequence, order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            template
            title
            subtitle
            date
          }
        }
      }
    }
    allContentfulNews(
      filter: {
        publishTo: {
          elemMatch: { url: { eq: "https://www.unicom.digital" } }
        }
      }
      sort: { fields: date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          slug
          title
          date
          template {
            name
          }
        }
      }
    }
    legal: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "legal" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    heroImage: file(name: { eq: "sitemap-banner" }) {
      childImageSharp {
        fluid(maxWidth: 1980, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
